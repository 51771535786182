@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.8/dist/web/static/pretendard.css');

@import './base.scss';

body.layout-v3 {
  font-weight: 500;
  font-size: 14px;
  background: #000;

  @media (min-width: 901px) {
    font-size: 16px;
  }

  a,
  a:hover,
  a:active,
  button {
    cursor: pointer;
    color: #000;
  }

  section {
    position: relative;
    width: 100%;
    overflow-x: hidden;
  }
  section article {
    display: flex;
    flex-flow: column nowrap;
    position: relative;
    width: 100%;
    max-width: 1280px;
    height: 100%;
    padding: 0 20px 20px 20px;
    margin: 0 auto;
  }
  section article .title {
    margin: 40px 0 30px 0;
  }
  section article .title h1 {
    font-size: 30px;
    font-weight: 700;
    padding-bottom: 6px;
    border-bottom: solid 3px;
  }
  section article .title p {
    line-height: 1.6;
    margin-top: 12px;
  }
  section article .title.no-p {
    margin-bottom: 0;
  }
  @media (min-width: 901px) {
    section article {
      padding: 10px 70px 60px 70px;
    }
    section article .title {
      margin: 60px 0;
    }
    section article .title h1 {
      font-size: 44px;
      padding-bottom: 16px;
      border-bottom: solid 3px;
    }
    section article .title p {
      font-size: 16px;
      margin-top: 12px;
    }
  }

  header,
  footer {
    width: 100%;
  }
  header article,
  footer article {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
  }

  .hidden {
    display: none;
  }

  @media (max-width: 900px) {
    .only-pc {
      display: none;
    }
  }
  @media (min-width: 901px) {
    .only-pc {
      display: block;
    }
  }

  @media (max-width: 900px) {
    .only-m {
      display: block;
    }
  }
  @media (min-width: 901px) {
    .only-m {
      display: none;
    }
  }

  .no-scroll {
    overflow: hidden;
  }

  .ko {
    line-height: 1.5;
  }

  .en {
    line-height: 1.3;
  }

  .youtube-box {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    background: #000;
  }
  .youtube-box iframe,
  .youtube-box img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .youtube-box .youtube-thumb {
    cursor: pointer;
  }

  .dbpq-box {
    position: relative;
    width: 100%;
    padding-top: calc(56.25% + 87px);
    background: #000;
  }
  .dbpq-box iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 54px;
    background: #000;
    color: #fff;
    z-index: 1000;
  }
  header article {
    z-index: 1000;
    position: relative;
  }
  header article .logo {
    position: absolute;
    top: 15px;
    left: 20px;
    height: 24px;
  }
  header article .lang {
    position: absolute;
    top: 19px;
    right: 20px;
    cursor: pointer;
    font-weight: 500;
    font-size: 18px;
    line-height: 0.8;
    text-align: center;
    text-decoration: underline;
    text-underline-offset: 0.2rem;
    text-decoration-color: #fff;
  }
  header article .header-rolling,
  header article .header-rolling .post-wrapper {
    position: absolute;
    top: 7px;
    right: 30px;
    width: 60px;
    font-weight: 400;
    font-size: 18px;
    color: #fff;
    text-decoration: underline;
    text-underline-offset: 0.2rem;
    text-decoration-color: #fff;
    z-index: 1001;
  }
  header article .header-rolling a,
  header article .header-rolling .post-wrapper a {
    color: #fff;
  }
  header article .header-rolling div,
  header article .header-rolling .post-wrapper div {
    height: 30px;
  }
  header article .menu {
    display: none;
  }
  header .progress-wrap {
    z-index: 999;
    position: absolute;
    top: 0;
    width: 100%;
    height: 54px;
    background: #000;
  }
  header .progress-wrap .progress {
    width: 0;
    height: 100%;
    background: #021bf5;
  }
  @media (max-width: 900px) {
    header article .logo {
      height: 22px;
      top: 16px;
    }
  }
  @media (min-width: 901px) {
    header article .menu {
      display: flex;
      position: absolute;
      top: 15px;
      right: 80px;
    }
    header article .menu a {
      font-size: 18px;
      color: #fff;
      margin-left: 50px;
    }
    header article .menu a:hover {
      text-decoration: underline;
      text-underline-offset: 0.2rem;
      text-decoration-color: #fff;
    }
  }

  footer {
    display: flex;
    align-items: center;
    height: 178px;
    background: #000;
    color: #fff;
    border-top: solid 3px #021bf5;
  }
  footer article {
    width: 100%;
    padding: 0 20px;
  }
  footer article div {
    position: relative;
    width: 100%;
    padding: 8px 0;
    border-bottom: solid 3px #eaeaea;
  }
  footer article div:last-child {
    border: none;
  }
  footer article div a {
    position: absolute;
    top: 8px;
    right: 0;
  }
  footer article div a,
  footer article div a:hover,
  footer article div a:active {
    color: #021bf5;
    text-decoration: underline;
    text-underline-offset: 0.2rem;
    text-decoration-color: #021bf5;
    text-decoration-thickness: 1px;
  }
  @media (min-width: 901px) {
    footer article {
      padding: 0 70px;
    }
  }

  section.main {
    height: 70vh;
    min-height: 500px;
    max-height: 700px;
    margin-top: 54px;
    background: #000000;
    user-select: none;
  }
  section.main article {
    height: 0;
    padding: 0;
  }
  section.main article h1 {
    font-size: 30px;
    font-weight: 600;
    line-height: 1.1;
    position: absolute;
    top: 16px;
    left: 18px;
    z-index: 100;
  }
  section.main article h1 span {
    background: #fff;
    padding: 4px 10px;
    line-height: 1.7;
  }
  section.main .main-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background: url('../images/main-background-1.png') center/cover no-repeat;
  }
  @media (min-width: 901px) {
    section.main {
      height: calc(100vh - 54px);
      min-height: 708px;
      max-height: 1600px;
    }
    section.main article h1 {
      font-size: 90px;
    }
    section.main article h1 span {
      padding: 4px 18px;
      line-height: 1.5;
    }
  }

  section.technology {
    color: #fff;
    background: #000;
  }
  section.technology article .title {
    margin-bottom: 80px;
  }
  section.technology article .title h1 {
    border-bottom: solid 3px;
  }
  section.technology article .title p.ko {
    line-height: 1.5;
  }
  section.technology article .title p.en {
    line-height: 1.3;
  }
  section.technology article h2 {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 80px;
  }
  section.technology article div.tech-research-area {
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: 80px;
  }
  section.technology article div.tech-research-area .poweredby {
    width: 138px;
    margin: 16px 0 22px 0;
  }
  section.technology article div.tech-research-area .keyword-wrap {
    display: flex;
    flex-flow: column nowrap;
  }
  section.technology article div.tech-research-area .keyword-wrap div {
    align-self: flex-start;
    font-size: 14px;
    font-weight: 500;
    padding: 8px;
    margin: 0 12px 12px 0;
    border: solid 2px #021bf5;
  }
  section.technology article div.tech-product-lineup .product-img {
    display: block;
    width: 100%;
    margin: 10px 0 40px 0;
  }
  section.technology article div.tech-product-lineup .product-img img {
    width: 100%;
  }
  section.technology article div.tech-product-lineup .product-wrap {
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: 50px;
  }
  section.technology
    article
    div.tech-product-lineup
    .product-wrap
    .product-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 166px;
    height: 70px;
    margin-bottom: 16px;
    background: transparent;
  }
  section.technology
    article
    div.tech-product-lineup
    .product-wrap
    .product-logo
    img {
    width: 100%;
  }

  section.technology
    article
    div.tech-product-lineup
    .product-wrap
    .product-text
    h3 {
    display: inline-block;
    align-self: flex-start;
    font-size: 22px;
    font-weight: 500;
    width: 100%;
    padding-bottom: 8px;
    margin-bottom: 12px;
    border-bottom: solid 3px #021bf5;
  }
  section.technology
    article
    div.tech-product-lineup
    .product-wrap
    .product-text
    a.product-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 342px;
    min-width: 200px;
    padding: 19px 20px 19px 32px;
    height: 35px;
    color: #fff;
    background: #0a5bff;
    border-radius: 4px;
    font-size: 22px;
  }
  section.technology article div.tech-demo-wrap {
    display: flex;
    flex-flow: column nowrap;
  }
  section.technology article div.tech-demo-wrap .tech-demo {
    width: 100%;
  }
  section.technology article div.tech-demo-wrap .tech-demo .demo-logo {
    position: relative;
    height: 20px;
    margin-bottom: 10px;
  }
  section.technology article div.tech-demo-wrap .tech-demo .demo-logo img {
    position: absolute;
    top: 0;
    left: 0;
  }
  section.technology article div.tech-demo-wrap .tech-demo .demo-logo img.svs {
    height: 18px;
  }
  section.technology article div.tech-demo-wrap .tech-demo .demo-logo img.cvc {
    height: 20px;
  }
  section.technology article div.tech-demo-wrap .tech-demo h3 {
    display: inline-block;
    align-self: flex-start;
    font-size: 22px;
    font-weight: 500;
    width: 100%;
    padding-bottom: 8px;
    margin-bottom: 12px;
    border-bottom: solid 3px #021bf5;
  }
  section.technology article div.tech-demo-wrap .tech-demo p {
    margin-bottom: 24px;
  }
  section.technology article div.tech-demo-wrap .tech-demo:first-child {
    margin-bottom: 60px;
  }
  section.technology article div.tech-demo-wrap .tech-demo:last-child {
    margin-bottom: 18px;
  }
  section.technology article .divider {
    width: 100%;
    height: 3px;
    background: #fff;
    margin: 30px 0 90px 0;
  }
  @media (min-width: 901px) {
    section.technology article .title {
      margin-bottom: 100px;
    }
    section.technology article h2 {
      font-size: 40px;
    }
    section.technology article div.tech-research-area {
      margin-bottom: 100px;
    }
    section.technology article div.tech-research-area .poweredby {
      width: 180px;
      margin: 26px 0 36px 0;
    }
    section.technology article div.tech-research-area .keyword-wrap {
      display: flex;
      flex-flow: row wrap;
    }
    section.technology article div.tech-research-area .keyword-wrap div {
      font-size: 17px;
      padding: 8px 10px;
      margin: 0 16px 16px 0;
      border: solid 2px #021bf5;
    }
    section.technology article div.tech-product-lineup .product-img {
      padding: 0 20px;
      margin: 60px 0 90px 0;
    }
    section.technology article div.tech-product-lineup .product-wrap {
      flex-flow: row nowrap;
      margin-bottom: 80px;
    }
    section.technology
      article
      div.tech-product-lineup
      .product-wrap
      .product-logo {
      width: 300px;
      min-width: 300px;
      height: 140px;
      margin-right: 74px;
      margin-bottom: 0;
    }
    section.technology
      article
      div.tech-product-lineup
      .product-wrap
      .product-text {
      width: 100%;
    }
    section.technology
      article
      div.tech-product-lineup
      .product-wrap
      .product-text
      h3 {
      font-size: 28px;
      width: auto;
    }
    section.technology
      article
      div.tech-product-lineup
      .product-wrap
      .product-text
      a.product-btn {
      width: 360px;
      height: 48px;
      font-size: 20px;
    }
    section.technology
      article
      div.tech-product-lineup
      .product-wrap:last-child {
      margin-bottom: 0;
    }
    section.technology article div.tech-demo-wrap {
      flex-flow: row nowrap;
      justify-content: space-between;
      margin-bottom: 20px;
    }
    section.technology article div.tech-demo-wrap .tech-demo {
      width: calc(50% - 40px);
    }
    section.technology article div.tech-demo-wrap .tech-demo .demo-logo {
      height: 30px;
      margin-bottom: 20px;
    }
    section.technology
      article
      div.tech-demo-wrap
      .tech-demo
      .demo-logo
      img.svs {
      height: 26px;
    }
    section.technology
      article
      div.tech-demo-wrap
      .tech-demo
      .demo-logo
      img.cvc {
      height: 30px;
    }
    section.technology article div.tech-demo-wrap .tech-demo h3 {
      font-size: 28px;
      width: auto;
    }
    section.technology article div.tech-demo-wrap .tech-demo p {
      height: 84px;
    }
    section.technology article div.tech-demo-wrap .tech-demo:first-child,
    section.technology article div.tech-demo-wrap .tech-demo:last-child {
      margin-bottom: 0;
    }
    section.technology article .divider {
      margin: 80px 0;
    }
  }

  section.pub-n-awards {
    background: #eaeaea;
  }

  section.pub-n-awards.only-m .title h1 {
    letter-spacing: -0.5pt;
  }
  section.pub-n-awards.only-m ul {
    border-bottom: solid 1px #000000;
  }
  section.pub-n-awards.only-m ul li {
    padding: 18px 0;
    border-bottom: solid 1px #000000;
  }
  section.pub-n-awards.only-m ul li h2 {
    font-weight: 700;
    font-size: 14px;
    padding-bottom: 8px;
  }
  section.pub-n-awards.only-m ul li h2.award {
    padding-bottom: 16px;
  }
  section.pub-n-awards.only-m ul li p {
    font-size: 10px;
    padding-bottom: 16px;
  }
  section.pub-n-awards.only-m ul li a {
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: #888;
    text-decoration: underline;
    text-underline-offset: 0.3rem;
    text-decoration-color: #021bf5;
    display: block;
    margin: 0 auto;
  }
  section.pub-n-awards.only-m ul li img {
    margin-bottom: 12px;
  }
  section.pub-n-awards.only-m ul .more {
    height: auto;
    overflow: hidden;
    transition: height 2s ease;
  }
  section.pub-n-awards.only-m ul .more.hidden {
    height: 0;
    transition: height 2s ease;
  }
  section.pub-n-awards.only-m .more-btn {
    padding: 24px 24px 0 24px;
  }

  section.pub-n-awards.only-pc article {
    padding-bottom: 0;
  }
  section.pub-n-awards.only-pc article .pna-inner-wrap {
    display: flex;
    flex-flow: row nowrap;
  }
  section.pub-n-awards.only-pc article .pna-inner-wrap .pna-inner {
    width: 50%;
  }
  section.pub-n-awards.only-pc article .pna-inner-wrap .pna-inner:nth-child(1) {
    padding-right: 30px;
    padding-top: 15px;
  }
  section.pub-n-awards.only-pc article .pna-inner-wrap .pna-inner:nth-child(2) {
    padding-left: 30px;
  }
  section.pub-n-awards.only-pc article .pna-inner-wrap .pna-inner ul li {
    padding: 32px 0 36px 0;
    border-bottom: solid 1px #000;
  }
  section.pub-n-awards.only-pc article .pna-inner-wrap .pna-inner ul li a {
    display: block;
    padding-bottom: 12px;
    font-weight: 500;
    font-size: 20px;
  }
  section.pub-n-awards.only-pc article .pna-inner-wrap .pna-inner ul li a.no-p {
    padding-bottom: 0;
  }
  section.pub-n-awards.only-pc article .pna-inner-wrap .pna-inner ul li p {
    font-size: 12px;
  }

  section.usecases {
    background: #eaeaea;
  }
  section.usecases .title {
    margin-bottom: 26px;
  }
  section.usecases h2 {
    font-size: 24px;
    font-weight: 700;
    padding-bottom: 6px;
  }
  section.usecases h3 {
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 8px;
  }
  section.usecases p {
    font-size: 14px;
    padding-bottom: 32px;
  }
  section.usecases .more {
    height: auto;
    overflow: hidden;
    transition: height 2s ease;
    border-bottom: solid 2px #000000;
  }
  section.usecases .more.hidden {
    height: 0;
    transition: height 2s ease;
  }
  section.usecases .more-btn {
    padding: 24px;
  }
  @media (min-width: 901px) {
    section.usecases article .title {
      margin-bottom: 48px;
    }
    section.usecases article h2 {
      font-size: 32px;
      padding-bottom: 8px;
    }
    section.usecases article h3 {
      font-size: 20px;
      padding-bottom: 10px;
    }
    section.usecases article p {
      font-size: 16px;
      padding-bottom: 55px;
    }
  }

  section.trustedby {
    background: #d6d6d6;
  }
  section.trustedby picture {
    width: 90%;
    margin: 24px auto 40px auto;
  }
  section.trustedby picture img {
    width: 100%;
  }

  section.ethics {
    background: #fcfe54;
    padding-bottom: 8px;
  }
  section.ethics p,
  section.ethics li {
    line-height: 1.5;
  }
  section.ethics p {
    font-weight: 500;
    font-size: 14px;
    margin-top: 26px;
    margin-bottom: 20px;
  }
  section.ethics li {
    padding-left: 20px;
    margin-top: 40px;
    border-left: solid 3px #000;
  }
  section.ethics li h2 {
    font-weight: 700;
    font-size: 16px;
  }
  section.ethics li p {
    font-weight: 500;
    font-size: 14px;
    margin-top: 4px;
  }
  section.ethics h2.ko,
  section.ethics p.ko {
    line-height: 1.5;
  }
  section.ethics h2.en,
  section.ethics p.en {
    line-height: 1.3;
  }
  @media (min-width: 901px) {
    section.ethics article {
      padding-bottom: 70px;
    }
    section.ethics article .ethics-inner-wrap {
      display: flex;
      flex-flow: row nowrap;
    }
    section.ethics article .ethics-inner-wrap > div {
      width: 50%;
    }
    section.ethics article .ethics-inner-wrap > div:nth-child(1) {
      padding-right: 30px;
    }
    section.ethics article .ethics-inner-wrap > div:nth-child(2) {
      padding-left: 30px;
    }
    section.ethics article .ethics-inner-wrap p:first-child {
      margin-top: 0px;
    }
    section.ethics article .ethics-inner-wrap h2,
    section.ethics article .ethics-inner-wrap p {
      font-size: 16px;
    }
  }

  section.contact {
    color: #fff;
    background: #000;
  }
  section.contact .title h1 {
    border-bottom: solid 3px #fff;
  }
  section.contact a {
    font-weight: 500;
  }
  section.contact .process h1 {
    font-weight: 700;
    color: #021bf5;
  }
  section.contact .process h2 {
    font-weight: 700;
  }
  section.contact .process p {
    font-weight: 500;
  }
  section.contact .process a {
    text-align: center;
  }
  section.contact .steps ul h3 {
    font-weight: 700;
    font-size: 18px;
  }
  section.contact .steps ul h3.en,
  section.contact .steps ul h3.ko {
    line-height: 1;
  }
  section.contact .steps ul div {
    font-weight: 700;
    font-size: 20px;
    line-height: 1;
    text-align: center;
    color: #000;
  }
  section.contact .necessity a {
    font-weight: 500;
    text-align: left;
    color: #fff;
    text-decoration: underline;
    text-underline-offset: 0.4rem;
    text-decoration-color: #021bf5;
  }
  section.contact .more {
    font-weight: 500;
    text-align: center;
  }
  section.contact .more p {
    font-size: 16px;
  }
  section.contact .more a {
    font-size: 18px;
    color: #fff;
  }

  section.contact.only-m .title h1 {
    padding-bottom: 10px;
  }
  section.contact.only-m .process {
    margin: 16px 0;
  }
  section.contact.only-m .process h1 {
    font-size: 30px;
    margin-bottom: 18px;
  }
  section.contact.only-m .process h2 {
    font-size: 24px;
    margin-bottom: 15px;
  }
  section.contact.only-m .process p {
    font-size: 14px;
    margin-bottom: 15px;
  }
  section.contact.only-m .process a {
    font-size: 13px;
  }
  section.contact.only-m .steps {
    margin-bottom: 20px;
    border-bottom: solid 1px #fff;
  }
  section.contact.only-m .steps ul {
    position: relative;
    padding-right: 8px;
    margin: 30px 0 0 20px;
    border-left: solid 3px #021bf5;
  }
  section.contact.only-m .steps ul h3 {
    margin-bottom: 10px;
  }
  section.contact.only-m .steps ul h3:last-child {
    margin-bottom: 50px;
  }
  section.contact.only-m .steps ul p {
    margin-bottom: 40px;
  }
  section.contact.only-m .steps ul li {
    position: relative;
    padding-left: 34px;
  }
  section.contact.only-m .steps ul li div {
    position: absolute;
    top: -7px;
    left: -18px;
    width: 34px;
    height: 34px;
    padding-top: 7px;
    border-radius: 20px;
    background: #021bf5;
  }
  section.contact.only-m .necessity {
    padding: 18px 0 18px 0;
  }
  section.contact.only-m .necessity p {
    padding-left: 12px;
    margin-left: 4px;
    border-left: solid 3px #fff;
  }
  section.contact.only-m .necessity a {
    position: relative;
    display: block;
    font-size: 14px;
    margin: -4px 0 21px 18px;
  }
  section.contact.only-m .necessity a.en {
    width: 300px;
  }
  section.contact.only-m .more {
    padding: 48px 0 30px 0;
    border-top: solid 1px #fff;
  }
  section.contact.only-m .more p {
    font-size: 14px;
    margin-bottom: 20px;
  }
  section.contact.only-m .more a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 240px;
    height: 44px;
    margin: 0 auto;
    border-radius: 50px;
    background: #021bf5;
  }

  section.contact.only-pc article .process h2 {
    font-size: 44px;
    color: #021bf5;
    padding-bottom: 40px;
  }
  section.contact.only-pc article .process .necessity {
    border-bottom: solid 1px #fff;
  }
  section.contact.only-pc article .process .necessity h2 {
    font-size: 24px;
    color: #fff;
    padding-bottom: 20px;
  }
  section.contact.only-pc article .process .necessity .necessity-inner-wrap {
    display: flex;
    flex-flow: row nowrap;
    padding-bottom: 70px;
    font-weight: 500;
    font-size: 16px;
  }
  section.contact.only-pc
    article
    .process
    .necessity
    .necessity-inner-wrap
    .necessity-inner {
    width: 33.3%;
    padding: 2px 14px;
    margin: 0 10px 0 3px;
    border-left: solid 3px #fff;
  }
  section.contact.only-pc
    article
    .process
    .necessity
    .necessity-inner-wrap
    .necessity-inner
    p,
  section.contact.only-pc
    article
    .process
    .necessity
    .necessity-inner-wrap
    .necessity-inner
    a {
    font-size: 16px;
  }
  section.contact.only-pc article .process .steps {
    padding-bottom: 60px;
    border-bottom: solid 1px #fff;
  }
  section.contact.only-pc article .process .steps ul {
    display: flex;
    flex-flow: row nowrap;
  }
  section.contact.only-pc article .process .steps ul.step-number {
    margin: 82px 22px 48px 22px;
  }
  section.contact.only-pc article .process .steps ul.step-number li {
    position: relative;
    width: 25%;
    border-top: solid 3px #021bf5;
  }
  section.contact.only-pc article .process .steps ul.step-number li div {
    position: absolute;
    top: -22px;
    width: 44px;
    height: 44px;
    padding-top: 10px;
    border-radius: 50px;
    background: #021bf5;
    font-weight: 700;
    font-size: 24px;
  }
  section.contact.only-pc
    article
    .process
    .steps
    ul.step-number
    li
    div.step-left {
    left: -22px;
  }
  section.contact.only-pc article .process .steps ul.step-number li div.step-5 {
    right: -22px;
  }
  section.contact.only-pc article .process .steps ul li {
    position: relative;
    width: calc(25% - 11px);
  }
  section.contact.only-pc article .process .steps ul li h3 {
    font-size: 24px;
    padding-bottom: 24px;
  }
  section.contact.only-pc article .process .steps ul li h3.step-5 {
    position: absolute;
    top: 0;
    right: -40px;
  }
  section.contact.only-pc article .process .steps ul li p {
    width: calc(100% - 40px);
    font-weight: 500;
    font-size: 16px;
  }
  section.contact.only-pc article .process .process-bottom .brief-wrap p {
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    margin: 80px 0 24px 0;
  }
  section.contact.only-pc article .process .process-bottom .brief-wrap a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 340px;
    min-width: 340px;
    height: 63px;
    margin: 0 auto 24px auto;
    background: #021bf5;
    border-radius: 50px;
    font-weight: 500;
    font-size: 24px;
    color: #fff;
  }

  section.proposal {
    margin-top: 54px;
    background: #d6d6d6;
  }
  section.proposal article {
    padding: 14px 20px 14px 20px;
  }

  section.people {
    height: 650px;
    margin-top: 54px;
    border: solid 22px #021bf5;
    background: #000;
    background: url('../images/people-m.png');
    background-size: cover;
    background-position: center;
  }
  section.people article h1 {
    font-size: 34px;
    font-weight: 700;
    line-height: 1.2;
    color: #021bf5;
    position: absolute;
    top: 14px;
    left: 22px;
  }
  @media (min-width: 901px) {
    section.people {
      height: calc(100vh - 54px);
      min-height: 750px;
      margin-top: 54px;
      border: solid 28px #021bf5;
      background: url('../images/people-pc.png');
      background-size: cover;
      background-position: center;
    }
    section.people article h1 {
      position: absolute;
      top: 28px;
      left: 20px;
      font-size: 80px;
    }
  }

  section.vision {
    background: #eaeaea;
  }
  section.vision article h2 {
    font-weight: 700;
    font-size: 30px;
    line-height: 1.2;
    text-align: center;
    color: #021bf5;
    padding: 24px 0;
    margin-bottom: 24px;
    border-bottom: solid 3px #021bf5;
  }
  section.vision article p {
    font-weight: 500;
  }
  section.vision article .p-1 {
    margin: 0 0 16px 0;
  }
  section.vision article .p-2 {
    margin: 0 0 12px 0;
  }
  @media (min-width: 901px) {
    section.vision article .vision-inner-wrap {
      display: flex;
      flex-flow: row nowrap;
    }
    section.vision article .vision-inner-wrap > div {
      width: 50%;
    }
    section.vision article .vision-inner-wrap > div:nth-child(1) {
      padding-right: 30px;
    }
    section.vision article .vision-inner-wrap > div:nth-child(2) {
      padding-left: 30px;
    }
    section.vision article .vision-inner-wrap > div h2 {
      padding: 0 0 58px 0;
      margin-bottom: 65px;
      font-size: 44px;
    }
    section.vision article .vision-inner-wrap > div p {
      font-size: 16px;
    }
  }

  section.history {
    background: #eaeaea;
  }
  section.history article .column {
    font-size: 13px;
    display: flex;
    flex-flow: row nowrap;
    padding: 16px 0;
    border-bottom: solid 1px #000;
  }
  section.history article .column div:nth-child(1) {
    width: 50px;
  }
  section.history article .column div:nth-child(2) {
    width: 100%;
  }
  section.history article .more {
    height: auto;
    overflow: hidden;
    border-bottom: solid 1px #000;
    transition: height 2s ease;
  }
  section.history article .more.hidden {
    height: 0;
    transition: height 2s ease;
  }
  section.history article .more-btn {
    padding: 24px 24px 0 24px;
  }
  section.history article .column,
  section.history article .column .en,
  section.history article .column .ko,
  section.history article .history-li,
  section.history article .history-li .en,
  section.history article .history-li .ko {
    line-height: 1.3;
  }
  @media (min-width: 901px) {
    section.history article {
      padding-bottom: 0;
    }
    section.history article .history-inner-wrap {
      display: flex;
      flex-flow: row nowrap;
      font-size: 16px;
    }
    section.history article .history-inner-wrap .history-inner {
      width: 50%;
      padding: 36px 0;
    }
    section.history article .history-inner-wrap .history-inner.left {
      padding-right: 30px;
    }
    section.history article .history-inner-wrap .history-inner.right {
      padding-left: 30px;
    }
    section.history article .history-inner-wrap .history-inner .column {
      font-size: 16px;
      display: flex;
      flex-flow: row nowrap;
      border-bottom: solid 1px #000;
      padding: 36px 0;
    }
    section.history
      article
      .history-inner-wrap
      .history-inner
      .column
      div:first-child {
      width: 58px;
      min-width: 58px;
    }
    section.history
      article
      .history-inner-wrap
      .history-inner
      .column
      div:last-child {
      width: 100%;
    }
    section.history
      article
      .history-inner-wrap
      .history-inner
      .column:last-child {
      border: none;
    }
  }

  section.team {
    background: #eaeaea;
  }
  section.team.team-hidden {
    display: none;
  }
  section.team article .random {
    font-weight: 700;
    font-size: 30px;
    height: 343px;
    padding: 4px 12px;
    border: solid 10px #021bf5;
    user-select: none;
    overflow: hidden;
  }
  section.team article .random .random-ko {
    letter-spacing: -0.05pt;
    line-height: 1.5;
  }
  section.team article .random .random-en {
    letter-spacing: 0pt;
    line-height: 1.3;
  }
  section.team article .random em {
    color: #021bf5;
  }
  section.team article .random em.name {
    text-decoration: underline;
    text-underline-offset: 0.4rem;
  }
  section.team article .random em.name:hover {
    color: #fff;
  }
  section.team article .click-caption {
    font-weight: 500;
    font-size: 30px;
    text-decoration: underline;
    text-underline-offset: 0.4rem;
    display: flex;
    flex-flow: row nowrap;
    margin: -60px 22px 26px 0;
    cursor: pointer;
    user-select: none;
    pointer-events: none;
  }
  section.team article .click-caption .blank {
    width: 100%;
  }
  section.team article button.view-all {
    font-size: 18px;
    font-weight: 500;
    color: #eaeaea;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 220px;
    height: 44px;
    margin: 12px auto 24px auto;
    border-radius: 50px;
    background: #021bf5;
  }
  section.team article .member-ul {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    width: 100%;
    max-width: 400px;
    min-height: 168px;
    padding: 0 2px;
    margin: 0 auto;
  }
  section.team article .member-ul.hidden {
    height: 168px;
    overflow: hidden;
  }
  section.team article .member-ul.ko .member-name-ko {
    display: inline-block;
    line-height: 1.1;
  }
  section.team article .member-ul.ko .member-name-en {
    display: none;
  }
  section.team article .member-ul.en .member-name-en {
    display: inline-block;
    line-height: 1.1;
  }
  section.team article .member-ul.en .member-name-ko {
    display: none;
  }
  section.team article .member-ul .member-li {
    text-align: center;
    width: 98px;
    height: 168px;
    margin: 0 6px;
    cursor: pointer;
  }
  section.team article .member-ul .member-li .member-img {
    width: 100%;
    border-radius: 100%;
    border: solid 3px #9f9f9f;
    filter: grayscale(1);
  }
  section.team article .member-ul .member-li.hide {
    display: none;
  }
  section.team article .member-ul .member-li p {
    font-weight: 700;
    font-size: 14px;
    line-height: 1;
    margin-top: 4px;
    margin-bottom: 2px;
  }
  section.team article .member-ul .member-li p.en {
    font-size: 12px;
    white-space: nowrap;
  }
  section.team article .member-ul .member-li p:last-child {
    font-weight: 400;
    font-size: 10px;
    line-height: 1;
    white-space: nowrap;
    margin: 0 0 20px 0;
  }
  section.team article .member-ul .member-li p .member-name-en {
    font-size: 13px;
  }
  section.team article .member-ul .member-li.air {
    height: 0px;
  }
  section.team article .member-ul .member-li.air.hide {
    display: block;
    height: 0px;
  }
  section.team article .member-ul .member-hidden {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    justify-content: space-between;
  }
  section.team article .member-filter {
    display: flex;
    flex-flow: row wrap;
    max-width: 680px;
    padding: 0 4px;
    margin: 32px auto;
  }
  section.team article .member-filter .filter {
    font-weight: 500;
    font-size: 14px;
    width: auto;
    padding: 6px 12px;
    margin: 4px 2px;
    border: solid 2px #000;
    border-radius: 50px;
    cursor: pointer;
    user-select: none;
  }
  section.team article .member-filter .filter.checked {
    background: #fff;
  }
  @media (min-width: 901px) {
    section.team article .random {
      height: 878px;
      padding: 8px 24px;
      font-size: 70px;
      border: solid 12px #021bf5;
      cursor: pointer;
    }
    section.team article .random em {
      color: #021bf5;
    }
    section.team article .random em.name {
      text-underline-offset: 1rem;
    }
    section.team article .click-caption {
      font-size: 80px;
      line-height: 1;
      margin: -62px 16px 0 0;
      text-underline-offset: 1rem;
    }
    section.team article .click-caption span {
      margin: -62px 22px 44px 0;
    }
    section.team article button.view-all {
      display: block;
      width: 260px;
      height: 63px;
      padding: 12px 0 14px 0;
      margin: 48px auto 24px auto;
      border-radius: 50px;
      background: #021bf5;
      font-size: 24px;
    }
    section.team article .member-ul {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      width: 100%;
      max-width: 840px;
      min-height: 456px;
      padding: 0 2px;
      margin: 0 auto;
    }
    section.team article .member-ul.hidden {
      height: 456px;
      overflow: hidden;
    }
    section.team article .member-ul .member-li {
      width: 130px;
      height: 228px;
    }
    section.team article .member-ul .member-li .member-img {
      width: 100%;
      border-radius: 100%;
      border: solid 3px #9f9f9f;
      filter: grayscale(1);
    }
    section.team article .member-ul .member-li p {
      margin-top: 10px;
      margin-bottom: 6px;
      font-size: 20px;
    }
    section.team article .member-ul .member-li p.en {
      font-size: 16px;
      white-space: nowrap;
    }
    section.team article .member-ul .member-li p:last-child {
      margin: 0 0 40px 0;
      font-size: 12px;
    }
    section.team article .member-ul .member-li p .member-name-en {
      font-size: 18px;
    }
    section.team article .member-filter {
      max-width: 840px;
      padding: 0 2px;
      margin: 60px auto;
    }
    section.team article .member-filter .filter {
      font-size: 16px;
      padding: 8px 24px;
      margin: 6px 4px;
    }
  }

  div.team-detail-wrap {
    display: flex;
    display: none;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 54px;
    left: 0;
    width: 100vw;
    height: calc(100% - 54px);
    background: rgba(0, 0, 0, 0.8);
    z-index: 500;
  }
  div.team-detail-wrap.show {
    display: flex;
  }
  div.team-detail-wrap .team-detail {
    position: relative;
    width: calc(100% - 80px);
    max-width: 900px;
    height: calc(100% - 80px);
    max-height: 1000px;
    background: #021bf5;
    margin: 40px;
    border-radius: 4px;
    overflow: scroll;
    -ms-overflow-style: none;
  }
  div.team-detail-wrap .team-detail::-webkit-scrollbar {
    display: none;
  }
  div.team-detail-wrap .team-detail .team-detail-inner {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    overflow-y: scroll;
    -ms-overflow-style: none;
  }
  div.team-detail-wrap .team-detail .team-detail-inner::-webkit-scrollbar {
    display: none;
  }
  div.team-detail-wrap .team-detail img.close {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 20px;
    cursor: pointer;
  }
  div.team-detail-wrap .team-detail .team-detail-profile-wrap {
    display: flex;
    flex-flow: column nowrap;
    background: #eaeaea;
  }
  div.team-detail-wrap .team-detail .team-detail-profile-wrap h1,
  div.team-detail-wrap .team-detail .team-detail-profile-wrap h2 {
    line-height: 1;
  }
  div.team-detail-wrap
    .team-detail
    .team-detail-profile-wrap
    .team-detail-name-ko,
  div.team-detail-wrap
    .team-detail
    .team-detail-profile-wrap
    .team-detail-name-en {
    margin-bottom: 12px;
  }
  div.team-detail-wrap
    .team-detail
    .team-detail-profile-wrap
    .team-detail-role {
    margin-bottom: 32px;
  }
  div.team-detail-wrap .team-detail .team-detail-img {
    width: 100%;
    max-width: 400px;
    padding: 40px 60px 20px 60px;
    margin: 0 auto;
  }
  div.team-detail-wrap .team-detail .team-detail-img img {
    width: 100%;
    border-radius: 1000px;
    border: solid 4px #9f9f9f;
    filter: grayscale(1);
  }
  div.team-detail-wrap .team-detail h1 {
    font-weight: 700;
    font-size: 30px;
    text-align: center;
  }
  div.team-detail-wrap .team-detail h2 {
    font-weight: 500;
    font-size: 14px;
    text-align: center;
  }
  div.team-detail-wrap .team-detail p {
    font-weight: 700;
    font-size: 24px;
    padding: 4px 12px;
    margin: 12px;
    background: #eaeaea;
  }
  div.team-detail-wrap .team-detail p em {
    color: #021bf5;
  }
  div.team-detail-wrap .team-detail.ko .team-detail-name-en,
  div.team-detail-wrap .team-detail.ko .team-detail-text-1-en,
  div.team-detail-wrap .team-detail.ko .team-detail-text-2-en,
  div.team-detail-wrap .team-detail.ko .team-detail-text-3-en,
  div.team-detail-wrap .team-detail.ko .team-detail-text-4-en,
  div.team-detail-wrap .team-detail.ko .team-detail-text-5-en,
  div.team-detail-wrap .team-detail.ko .team-detail-text-4-ko,
  div.team-detail-wrap .team-detail.ko .team-detail-text-5-ko {
    display: none;
  }
  div.team-detail-wrap .team-detail.ko .team-detail-name-ko,
  div.team-detail-wrap .team-detail.ko .team-detail-text-1-ko,
  div.team-detail-wrap .team-detail.ko .team-detail-text-2-ko,
  div.team-detail-wrap .team-detail.ko .team-detail-text-3-ko,
  div.team-detail-wrap .team-detail.ko .team-detail-text-4-ko.show,
  div.team-detail-wrap .team-detail.ko .team-detail-text-5-ko.show {
    display: block;
  }
  div.team-detail-wrap .team-detail.en .team-detail-name-ko,
  div.team-detail-wrap .team-detail.en .team-detail-text-1-ko,
  div.team-detail-wrap .team-detail.en .team-detail-text-2-ko,
  div.team-detail-wrap .team-detail.en .team-detail-text-3-ko,
  div.team-detail-wrap .team-detail.en .team-detail-text-4-ko,
  div.team-detail-wrap .team-detail.en .team-detail-text-5-ko,
  div.team-detail-wrap .team-detail.en .team-detail-text-4-en,
  div.team-detail-wrap .team-detail.en .team-detail-text-5-en {
    display: none;
  }
  div.team-detail-wrap .team-detail.en .team-detail-name-en,
  div.team-detail-wrap .team-detail.en .team-detail-text-1-en,
  div.team-detail-wrap .team-detail.en .team-detail-text-2-en,
  div.team-detail-wrap .team-detail.en .team-detail-text-3-en,
  div.team-detail-wrap .team-detail.en .team-detail-text-4-en.show,
  div.team-detail-wrap .team-detail.en .team-detail-text-5-en.show {
    display: block;
  }
  @media (min-width: 901px) {
    div.team-detail-wrap .team-detail {
      border-radius: 6px;
    }
    div.team-detail-wrap .team-detail .team-detail-inner {
      border-radius: 6px;
    }
    div.team-detail-wrap
      .team-detail
      .team-detail-profile-wrap
      .team-detail-img {
      padding: 60px 60px 28px 60px;
    }
    div.team-detail-wrap
      .team-detail
      .team-detail-profile-wrap
      .team-detail-name-ko,
    div.team-detail-wrap
      .team-detail
      .team-detail-profile-wrap
      .team-detail-name-en {
      margin-bottom: 12px;
    }
    div.team-detail-wrap
      .team-detail
      .team-detail-profile-wrap
      .team-detail-role {
      margin-bottom: 58px;
    }
    div.team-detail-wrap .team-detail h1 {
      font-size: 50px;
    }
    div.team-detail-wrap .team-detail h2 {
      font-size: 24px;
    }
    div.team-detail-wrap .team-detail p {
      font-size: 50px;
      padding: 8px 22px;
      margin: 24px;
    }
  }

  section.join {
    color: #fff;
    background: #000;
  }
  section.join article .title h1 {
    border-bottom: solid 3px #fff;
  }
  section.join article .join-diagram {
    width: 264px;
    margin: 30px auto 24px auto;
  }
  section.join article .join-cards {
    margin-bottom: 54px;
  }
  section.join article p {
    font-weight: 700;
    font-size: 26px;
    line-height: 1.5;
  }
  section.join article .p-1 {
    margin: 40px 0 40px 0;
  }
  section.join article .p-1 em {
    padding-bottom: 1px;
    border-bottom: solid 3px #fff;
  }
  section.join article .p-2 {
    color: #021bf5;
    margin-bottom: 46px;
  }
  section.join article .p-2 em {
    padding-bottom: 1px;
    border-bottom: solid 3.5px #021bf5;
  }
  section.join article .divider {
    width: 196px;
    margin: 48px auto 40px auto;
    border-bottom: solid 3px #021bf5;
  }
  section.join article .p-3 {
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    color: #fff;
    margin-bottom: 16px;
  }
  section.join article a {
    font-size: 18px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 260px;
    height: 44px;
    margin: 0 auto;
    border-radius: 50px;
    background: #021bf5;
  }
  section.join article a:last-child {
    color: #fff;
    margin-bottom: 46px;
    border: solid 3px #021bf5;
    background: #000;
  }
  @media (min-width: 901px) {
    section.join article {
      padding-bottom: 94px;
    }
    section.join article .join-inner-wrap {
      display: flex;
      flex-flow: row nowrap;
      margin: 100px 0;
    }
    section.join article .join-inner-wrap .join-inner.left {
      width: 100%;
    }
    section.join article .join-inner-wrap .join-inner.right {
      width: 600px;
      padding: 0 30px;
      margin-left: 40px;
    }
    section.join article .join-inner-wrap .join-inner p {
      font-size: 32px;
    }
    section.join article .join-inner-bottom {
      display: flex;
      flex-flow: row nowrap;
    }
    section.join article .join-inner-bottom a {
      width: 360px;
      min-width: 360px;
      height: 63px;
      padding: 12px 0 14px 0;
      margin: 0;
      border-radius: 50px;
      border: solid 3px #021bf5;
      background: #021bf5;
      font-size: 24px;
    }
    section.join article .join-inner-bottom a:last-child {
      border: solid 3px #021bf5;
      background: #000;
    }
    section.join article .join-inner-bottom .mail-text {
      width: 100%;
      padding: 22px 22px;
      font-weight: 500;
      font-size: 16px;
      text-align: right;
    }
  }

  .media-title h3 {
    font-size: 18px;
    line-height: 1.3;
    margin-top: 20px;
    margin-bottom: 6px;
  }
  .media-title h3.ko,
  .media-title h3.en,
  .media-title h3 a,
  .media-title h3 a.ko,
  .media-title h3 a.en {
    line-height: 1.3;
  }
  .media-title p {
    font-size: 12px;
    margin-bottom: 8px;
  }
  .media-title p:last-child {
    font-size: 10px;
    font-weight: 400;
    margin-bottom: 0;
  }
  .media-title p,
  .media-title p.ko,
  .media-title p.en {
    line-height: 1.4;
  }
  @media (min-width: 901px) {
    .main-video {
      padding-bottom: 10px !important;
    }
    .media-title h3 {
      font-size: 20px;
      margin-top: 0;
      margin-bottom: 12px;
      word-wrap: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    .media-title p {
      font-size: 14px;
      margin-bottom: 14px;
    }
    .media-title p:last-child {
      font-size: 12px;
    }
  }

  .media-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 280px;
    height: 44px;
    margin: 22px auto 40px auto;
    border-radius: 500px;
    font-size: 18px;
    color: #fff;
    background: #021bf5;
  }
  .media-button:hover {
    color: #fff;
  }
  @media (min-width: 901px) {
    .media-button {
      width: 360px;
      height: 62px;
      margin: 48px auto 20px auto;
      font-size: 24px;
    }
  }

  section.media {
    color: #fff;
    background: #000;
    margin-top: 54px;
  }
  section.media article .main-video {
    width: 100%;
    padding: 30px 0;
    border-bottom: solid 1px #fff;
  }
  section.media article .main-video img {
    width: 100%;
  }
  section.media article .youtube-box {
    margin-bottom: 20px;
  }
  section.media article ul li {
    padding: 30px 0;
    border-bottom: solid 1px #fff;
  }
  section.media article ul li:last-child {
    border-bottom: none;
  }
  @media (min-width: 901px) {
    section.media article .main-video {
      border-bottom: none;
    }
    section.media article .media-title {
      height: 100px;
    }
    section.media article ul {
      display: flex;
      flex-flow: row wrap;
    }
    section.media article ul li {
      width: 50%;
      padding: 40px 20px;
      border-bottom: none;
    }
    section.media article .media-button {
      margin-top: 40px;
    }
  }

  section.news {
    background: #eaeaea;
  }
  section.news article .news-container {
  }
  section.news article .news-container ul.news-list {
    display: flex;
    flex-wrap: wrap;
  }
  section.news article .news-container ul.news-list li {
    flex: 100%;
    padding: 28px 0 30px 0;
    border-bottom: solid 1px;
  }
  section.news article .news-container ul.news-list li .news-img {
    display: block;
    margin-top: 22px;
  }
  section.news article .news-container ul.news-list li .news-img img {
    display: block;
    width: 100%;
  }
  section.news article .news-container ul.news-list.right li:last-child {
    border-bottom: none;
  }
  @media (min-width: 901px) {
    section.news article .title {
      margin-bottom: 20px;
    }
    section.news article .news-container {
      flex-flow: row nowrap;
    }
    section.news article .news-container ul.news-list {
    }
    section.news article .news-container ul.news-list li {
      flex: 50%;
      width: 100%;
      padding: 30px 20px;
      border-bottom: none;
    }
  }

  section.news-page {
    background: #eaeaea;
    margin-top: 54px;
  }
  section.news-page article picture img {
    display: block;
    width: 100%;
    padding: 40px 26px;
    border-bottom: solid 3px;
  }
  section.news-page article .filters {
    display: flex;
    flex-flow: row nowrap;
    padding: 14px 0 10px 0;
  }
  section.news-page article .filters .filter {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 28px;
    margin-right: 6px;
    border: solid 3px;
    border-radius: 500px;
    font-size: 14px;
    font-weight: 700;
  }
  section.news-page article .filters .filter.checked {
    background: #fff;
  }
  section.news-page article ul.news-container {
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: 32px;
  }
  section.news-page article ul.news-container li {
    width: 100%;
    padding: 22px 2px;
    border-bottom: solid 1px;
  }
  section.news-page article ul.news-container li h3 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 6px;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  section.news-page article ul.news-container li h3,
  section.news-page article ul.news-container li h3 a,
  section.news-page article ul.news-container li h3 a.ko,
  section.news-page article ul.news-container li h3 a.en,
  section.news-page article ul.news-container li p {
    line-height: 1.3;
  }
  section.news-page article ul.news-container li p {
    font-size: 10px;
    font-weight: 400;
  }
  @media (min-width: 901px) {
    section.news-page article picture img {
      padding: 80px 60px;
      border-bottom: solid 3px;
    }
    section.news-page article .filters {
      padding: 22px 0 4px 0;
    }
    section.news-page article .filters .filter {
      width: 70px;
      height: 34px;
      margin-right: 8px;
      font-size: 18px;
    }
    section.news-page article ul.news-container {
      flex-flow: row wrap;
      justify-content: space-between;
    }
    section.news-page article ul.news-container li {
      width: calc(50% - 20px);
      padding: 44px 2px;
    }
    section.news-page article ul.news-container li h3 {
      font-size: 20px;
      margin-bottom: 12px;
      -webkit-line-clamp: 1;
    }
    section.news-page article ul.news-container li p {
      font-size: 12px;
    }
  }
}

.cookie-consent-popup {
  button {
    color: #fff !important;

    &.btn-primary {
      color: var(--blue) !important;
    }
  }

  a {
    color: #fff !important;
  }
}
