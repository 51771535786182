body.demo {
  img {
    display: block;
  }

  a {
    color: #1938ff;
  }

  iframe {
    width: 100%;
    height: 100%;
    aspect-ratio: 16 / 9;
  }

  font-family: 'Pretendard', sans-serif;
  letter-spacing: 0.2pt;
  word-spacing: -0.1pt;
  line-height: 1.2;
  color: #eaeaea;
  background: #111111;
  margin-top: 42px;

  header {
    background-color: unset;

    .logo-supertone {
      z-index: 1002;
      position: fixed;
      top: 56px;
      left: 60px;
      height: 25px;
    }
    @media (max-width: 900px) {
      .logo-supertone {
        top: 25px;
        left: 28px;
        height: 17px;
      }
    }
    .logo-interspeech {
      z-index: 1002;
      position: fixed;
      top: 60px;
      right: 60px;
      height: 18px;
    }
    @media (max-width: 900px) {
      .logo-interspeech {
        top: 28px;
        right: 28px;
        height: 12px;
      }
    }
    .logo-biff {
      z-index: 1002;
      position: fixed;
      top: 60px;
      right: 60px;
      height: 19px;
    }
    @media (max-width: 900px) {
      .logo-biff {
        top: 28px;
        right: 28px;
        height: 13px;
      }
    }
    .logo-poweredby {
      z-index: 1002;
      position: fixed;
      bottom: 60px;
      right: 60px;
      height: 22px;
    }
    @media (max-width: 900px) {
      .logo-poweredby {
        bottom: 28px;
        right: 28px;
        height: 14px;
      }
    }
    .logo-video {
      z-index: 1002;
      position: fixed;
      top: 57px;
      right: 60px;
      font-size: 24px;
      font-weight: 700;
      line-height: 1;
      color: #dddddd;
    }
  }

  nav {
    z-index: 999;
    position: fixed;
    bottom: 58px;
    left: 60px;
  }
  @media (max-width: 900px) {
    nav {
      display: none;
    }
  }
  nav * {
    z-index: 999;
  }
  nav a {
    font-size: 18px;
    font-weight: 500;
    line-height: 32px;
  }
  nav a,
  nav a:visited {
    color: #1938ff;
  }
  nav a:hover {
    color: #eaeaea;
  }
  nav.hidden {
    display: none;
  }

  div#grid-out * {
    z-index: 998;
  }
  div#grid-out div {
    position: fixed;
    background: #1938ff;
  }
  div#grid-out div:nth-child(n + 1):nth-child(-n + 4) {
    width: 4px;
    height: 30px;
  }
  div#grid-out div:nth-child(n + 5):nth-child(-n + 8) {
    width: 30px;
    height: 4px;
  }
  div#grid-out div:nth-child(1) {
    top: 0;
    left: 56px;
  }
  div#grid-out div:nth-child(2) {
    top: 0;
    right: 56px;
  }
  div#grid-out div:nth-child(3) {
    bottom: 0;
    left: 56px;
  }
  div#grid-out div:nth-child(4) {
    bottom: 0;
    right: 56px;
  }
  div#grid-out div:nth-child(5) {
    top: 56px;
    left: 0;
  }
  div#grid-out div:nth-child(6) {
    top: 56px;
    right: 0;
  }
  div#grid-out div:nth-child(7) {
    bottom: 56px;
    left: 0;
  }
  div#grid-out div:nth-child(8) {
    bottom: 56px;
    right: 0;
  }
  @media (max-width: 900px) {
    div#grid-out div:nth-child(n + 1):nth-child(-n + 4) {
      width: 3px;
      height: 14px;
    }
    div#grid-out div:nth-child(n + 5):nth-child(-n + 8) {
      width: 14px;
      height: 3px;
    }
    div#grid-out div:nth-child(1),
    div#grid-out div:nth-child(3) {
      left: 25px;
    }
    div#grid-out div:nth-child(2),
    div#grid-out div:nth-child(4) {
      right: 25px;
    }
    div#grid-out div:nth-child(5),
    div#grid-out div:nth-child(6) {
      top: 25px;
    }
    div#grid-out div:nth-child(7),
    div#grid-out div:nth-child(8) {
      bottom: 25px;
    }
  }

  div#grid-in {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    max-width: 1120px;
    height: 100%;
    margin: 0 auto;
    z-index: -1;
  }
  div#grid-in * {
    z-index: 998;
  }
  div#grid-in div {
    position: absolute;
    width: 4px;
    height: 30px;
    background: #1938ff;
  }
  div#grid-in div:nth-child(1) {
    top: 0;
    left: -4px;
  }
  div#grid-in div:nth-child(2) {
    top: 0;
    right: -4px;
  }
  div#grid-in div:nth-child(3) {
    bottom: 0;
    left: -4px;
  }
  div#grid-in div:nth-child(4) {
    bottom: 0;
    right: -4px;
  }
  @media (max-width: 1500px) {
    div#grid-in {
      display: none;
    }
  }

  section {
    position: relative;
    width: 100%;
    border-bottom: solid 4px #1938ff;
  }
  @media (max-width: 900px) {
    section {
      border-bottom: solid 3px #1938ff;
    }
  }

  article {
    position: relative;
    width: 100%;
    max-width: 1120px;
    padding: 98px 40px 120px 40px;
    margin: 0 auto;
  }
  @media (max-width: 900px) {
    article {
      max-width: 100%;
      padding: 70px 28px 90px 28px;
    }
  }
  article div.title-section {
    display: flex;
    flex-flow: row nowrap;
    position: relative;
    width: 650px;
    height: 66px;
    margin: 0 auto 120px auto;
  }
  @media (max-width: 900px) {
    article div.title-section {
      width: 280px;
      height: 30px;
      margin: 0 auto 50px auto;
    }
  }
  article div.title-section.two-lines {
    height: 132px;
  }
  @media (max-width: 900px) {
    article div.title-section.two-lines {
      height: 58px;
    }
  }
  article div.title-section div:nth-child(2) {
    width: 100%;
    font-size: 60px;
    font-weight: 500;
    line-height: 1.1;
    text-align: center;
    color: #eaeaea;
  }
  @media (max-width: 900px) {
    article div.title-section div:nth-child(2) {
      font-size: 24px;
      padding-top: 2px;
    }
  }
  article div.title-section div:nth-child(1),
  article div.title-section div:nth-child(3) {
    width: 14px;
    height: 100%;
    border: solid 4px #eaeaea;
  }
  @media (max-width: 900px) {
    article div.title-section div:nth-child(1),
    article div.title-section div:nth-child(3) {
      width: 12px;
      border: solid 3px #eaeaea;
    }
  }
  article div.title-section div:nth-child(1) {
    border-right: none;
  }
  article div.title-section div:nth-child(3) {
    border-left: none;
  }
  article div.logotype-wrap {
    width: 100%;
    text-align: center;
  }
  article img.logotype {
    margin: 0 auto 70px auto;
  }
  article img.logotype.vfd {
    width: 100%;
  }
  article img.logotype.cvc {
    width: 258px;
  }
  article img.logotype.svs {
    width: 215px;
  }
  article img.logotype.tts {
    width: 352px;
  }
  article img.logotype.raven {
    width: 220px;
  }
  article img.logotype.margin-top {
    margin-top: 50px;
  }
  @media (max-width: 900px) {
    article img.logotype {
      margin: 0 auto 50px auto;
    }
    article img.logotype.vfd {
      width: 100%;
    }
    article img.logotype.cvc {
      width: 132px;
    }
    article img.logotype.svs {
      width: 112px;
    }
    article img.logotype.tts {
      width: 172px;
    }
    article img.logotype.raven {
      width: 98px;
    }
  }
  article div.title-demo {
    font-size: 30px;
    font-weight: 500;
    line-height: 1.1;
    color: #1938ff;
    margin-left: -2px;
    margin-bottom: 24px;
  }
  @media (max-width: 900px) {
    article div.title-demo {
      font-size: 20px;
      margin-bottom: 12px;
    }
  }
  article div.title-demo-sub {
    letter-spacing: 0pt;
    word-spacing: -0.2pt;
    font-size: 14px;
    font-weight: 500;
    color: #1938ff;
    padding: 0 0 2px 8px;
    margin-top: -14px;
    margin-bottom: 24px;
    border-left: solid 4px #1938ff;
  }
  @media (max-width: 900px) {
    article div.title-demo-sub {
      font-size: 12px;
      padding: 2px 0 4px 6px;
      margin-top: -4px;
      margin-bottom: 12px;
      border-left: solid 3px #1938ff;
    }
  }

  article > section {
    border: none;
  }

  article > section .title-demo-section {
    margin-top: 30px;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0pt;
    word-spacing: -0.2pt;
    border-left: solid 4px #1938ff;
    color: #1938ff;
    padding: 0 0 2px 8px;
  }

  article div.caption {
    display: flex;
    flex-flow: row nowrap;
    font-size: 28px;
    font-weight: 500;
    color: #1938ff;
    margin-bottom: 24px;
  }
  article div.caption img.poweredby {
    height: 24px;
    margin: 5px 0 0 18px;
  }
  article div.caption.second {
    margin-top: -38px;
  }
  @media (max-width: 900px) {
    article div.caption {
      font-size: 14px;
      margin-bottom: 12px;
    }
    article div.caption img.poweredby {
      height: 15px;
      margin: 1px 0 0 10px;
    }
    article div.caption.second {
      margin-top: -10px;
    }
  }
  article div.dbpq-wrap {
    position: relative;
    width: calc(100% + 40px);
    padding-bottom: calc(52.5% + 96px);
    margin-left: -20px;
    margin-bottom: 80px;
    border: solid 4px #000;
  }
  @media (max-width: 900px) {
    article div.dbpq-wrap {
      width: calc(100% + 28px);
      margin-left: -14px;
      margin-bottom: 30px;
      border: solid 3px #000;
    }
  }
  article div.dbpq-wrap.enemy {
    padding-bottom: calc(56.25% + 118px);
  }
  article div.dbpq-wrap.slider {
    padding-bottom: calc(25% + 112px);
  }
  article div.dbpq-wrap.ending {
    padding-bottom: calc(44% + 96px);
  }
  article div.dbpq-wrap.silco {
    padding-bottom: calc(45% + 96px);
  }
  article div.dbpq-wrap.realtime {
    padding-bottom: calc(52.5% + 96px);
  }
  article div.dbpq-wrap iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    border: solid 4px #000;
  }
  @media (max-width: 900px) {
    article div.dbpq-wrap iframe {
      border: solid 3px #000;
    }
  }
  article div.video {
    position: relative;
    width: calc(100% + 40px);
    padding-bottom: calc(56.25% + 24px);
    margin-left: -20px;
    margin-bottom: 80px;
    border: solid 4px #000;
    background: #000;
  }
  @media (max-width: 900px) {
    article div.video {
      width: calc(100% + 28px);
      padding-bottom: calc(56.25% + 16px);
      margin-left: -14px;
      margin-bottom: 40px;
      border: solid 3px #000;
    }
  }
  article div.video video,
  article div.video img,
  article div.video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: solid 4px #000;
  }
  @media (max-width: 900px) {
    article div.video video,
    article div.video img,
    article div.video iframe {
      border: solid 3px #000;
    }
  }
  article div.video.second {
    margin-top: -50px;
  }
  @media (max-width: 900px) {
    article div.video.second {
      margin-top: -22px;
    }
  }
  article audio.audio {
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-bottom: 80px;
  }
  @media (max-width: 900px) {
    article audio.audio {
      width: calc(100% + 28px);
      margin-left: -14px;
      margin-bottom: 30px;
    }
  }

  div.warning {
    width: calc(100% - 40px);
    max-width: 880px;
    margin: 0 auto;
    padding: 34px 0 38px 0;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    color: #111111;
    background: #1938ff;
  }
  div.warning h1 {
    font-size: 20px;
    margin-bottom: 24px;
  }
  @media (max-width: 900px) {
    div.warning {
      width: calc(100% - 28px);
      padding: 25px 14px 32px 14px;
      font-size: 10px;
    }
    div.warning h1 {
      font-size: 16px;
      margin-bottom: 14px;
    }
  }

  section#section-intro {
    border-bottom: none;
  }
  section#section-intro article div.intro-wrap {
    padding: 30px 38px;
    border: solid 4px #1938ff;
  }
  section#section-intro article div.intro-wrap div {
    font-size: 16px;
    font-weight: 700;
    color: #1938ff;
  }
  section#section-intro article div.intro-wrap div.ko {
    letter-spacing: 0pt;
    word-spacing: -0.2pt;
    line-height: 1.5;
    margin-bottom: 18px;
  }
  section#section-intro article div.intro-wrap div.en {
    line-height: 1.3;
  }
  @media (max-width: 900px) {
    section#section-intro article {
      padding-bottom: 50px;
    }
    section#section-intro article div.intro-wrap {
      padding: 16px 22px;
    }
    section#section-intro article div.intro-wrap div {
      font-size: 14px;
    }
    section#section-intro article div.intro-wrap div.ko {
      margin-bottom: 14px;
    }
  }

  section#section-last article div.title-last {
    font-size: 60px;
    font-weight: 500;
    line-height: 1.1;
    text-align: center;
    color: #1938ff;
    margin: 80px auto;
  }
  section#section-last article div.title-last.second {
    margin-bottom: 45px;
  }
  @media (max-width: 900px) {
    section#section-last article div.title-last {
      font-size: 24px;
      margin: 40px auto 30px auto;
    }
  }
  section#section-last article div.title-last-sub {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.3;
    color: #1938ff;
    max-width: 760px;
    margin: 0 auto;
  }
  section#section-last article div.title-last-sub p {
    border-left: solid 4px #1938ff;
    padding-left: 8px;
    margin-bottom: 16px;
  }
  section#section-last article div.title-last-sub p.ko {
    letter-spacing: 0pt;
    word-spacing: -0.2pt;
    line-height: 1.5;
  }
  @media (max-width: 900px) {
    section#section-last article div.title-last-sub {
      font-size: 12px;
    }
    section#section-last article div.title-last-sub p {
      border-left: solid 3px #1938ff;
    }
  }
  section#section-last article img {
    width: 100%;
  }
  section#section-last article img.how-we-did-it {
    margin: 80px 0 200px 0;
  }
  section#section-last article img.core-technology {
    margin-bottom: 180px;
  }
  @media (max-width: 900px) {
    section#section-last article img.how-we-did-it {
      margin: 44px 0 120px 0;
    }
    section#section-last article img.core-technology {
      margin-bottom: 40px;
    }
  }

  footer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    position: relative;
    width: 100%;
    max-width: 1120px;
    padding: 0 40px;
    margin: 6px auto 6px auto;
    font-size: 14px;
    color: #1938ff;
    background: unset;
  }
  footer div:nth-child(2) {
    text-align: right;
  }

  footer article div {
    border-bottom: solid 3px #081af5;

    &:last-child {
      border: none;
    }
  }

  @media (max-width: 900px) {
    footer {
      flex-flow: column nowrap;
      padding: 0 14px;
      border-bottom: solid 3px #1938ff;
      margin: 0 auto 66px auto;
    }
    footer div {
      padding: 6px 14px;
      text-align: center;
    }
    footer div:nth-child(2) {
      border-top: solid 3px #1938ff;
      text-align: center;
    }
  }
  @media (max-width: 900px) {
    br.no-m {
      display: none;
    }
  }
  @media (min-width: 901px) {
    br.no-pc {
      display: none;
    }
  }
  .no-scroll {
    overflow: hidden;
  }

  section.entrance {
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #111111;
    z-index: 100;
    border: none;
  }
  section.entrance.hidden {
    display: none;
  }
  section.entrance article {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center;
  }
  section.entrance article p {
    font-size: 24px;
    font-weight: 500;
    padding-bottom: 20px;
    color: #eaeaea;
  }
  section.entrance article p:last-child {
    font-size: 12px;
    font-weight: 400;
  }
  section.entrance article #p1a2s3s4w5o6r7d {
    font-size: 18px;
    letter-spacing: 5pt;
    width: 300px;
    padding: 10px 20px;
    margin: 0 auto;
    margin-bottom: 10px;
    background: #eaeaea;
    border-radius: 500px;
  }
  section.entrance article #p1a2s3s4w5o6r7d:focus {
    outline: none;
  }
  section.entrance div.information {
    position: absolute;
    bottom: 90px;
    width: 700px;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    color: #eaeaea;
  }
  section.entrance div.information a,
  section.entrance div.information a:hover,
  section.entrance div.information a:visited {
    color: #eaeaea;
    border-bottom: solid 2px #eaeaea;
  }
  @media (max-width: 900px) {
    section.entrance div.information {
      width: calc(100% - 40px);
      font-size: 12px;
    }
  }

  @media (max-width: 900px) {
    section.entrance article #p1a2s3s4w5o6r7d {
      font-size: 18px;
      width: 228px;
    }
  }
  section.entrance article {
    padding: 98px 40px 140px 40px;
  }
  section.entrance article div.entrance-content {
    display: flex;
    flex-flow: column nowrap;
    width: 720px;
  }
  section.entrance article div.entrance-content div.agreement-wrap {
    text-align: left;
    padding: 24px 30px;
    margin-bottom: 12px;
    color: #111111;
    background: #1938ff;
  }
  section.entrance
    article
    div.entrance-content
    div.agreement-wrap
    div.agreement {
    font-size: 14px;
    font-weight: 700;
  }
  section.entrance
    article
    div.entrance-content
    div.agreement-wrap
    div.agreement.ko {
    letter-spacing: 0pt;
    word-spacing: -0.2pt;
    line-height: 1.4;
    margin-bottom: 14px;
  }
  section.entrance
    article
    div.entrance-content
    div.agreement-wrap
    div.agreement.en {
    line-height: 1.2;
  }
  section.entrance
    article
    div.entrance-content
    div.input-wrap
    div.checkbox-wrap {
    display: flex;
    flex-flow: row nowrap;
    width: 410px;
    padding: 0;
    margin: 0 auto 56px auto;
  }
  section.entrance
    article
    div.entrance-content
    div.input-wrap
    div.checkbox-wrap
    input#entrance-checkbox {
    appearance: none;
  }
  section.entrance
    article
    div.entrance-content
    div.input-wrap
    div.checkbox-wrap
    input#entrance-checkbox::before {
    content: '';
    display: inline-block;
    width: 12px;
    height: 12px;
    border: solid 4px #1938ff;
    border-radius: 6px;
    background: #111111;
    cursor: pointer;
  }
  section.entrance
    article
    div.entrance-content
    div.input-wrap
    div.checkbox-wrap
    input#entrance-checkbox:checked::before {
    background: #1938ff;
  }
  section.entrance
    article
    div.entrance-content
    div.input-wrap
    div.checkbox-wrap
    .checkbox-text {
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    color: #1938ff;
    padding: 0;
    padding-top: 2px;
    margin-left: 8px;
  }
  section.entrance
    article
    div.entrance-content
    div.input-wrap
    div.password-wrap {
    display: flex;
    flex-flow: column nowrap;
  }
  section.entrance
    article
    div.entrance-content
    div.input-wrap
    div.password-wrap
    p.password-text {
    font-size: 16px;
    padding-bottom: 0;
    color: #1938ff;
    margin-bottom: 8px;
  }
  section.entrance
    article
    div.entrance-content
    div.input-wrap
    div.password-wrap
    #p1a2s3s4w5o6r7d {
    width: 200px;
    height: 40px;
    padding: 10px 20px;
    margin: 0 auto 18px auto;
    border-radius: 16px;
    font-size: 12px;
    color: #1938ff;
    background: #111111;
    border: solid 4px #1938ff;
  }
  section.entrance
    article
    div.entrance-content
    div.input-wrap
    div.password-wrap
    #enter {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 40px;
    padding: 10px 20px;
    margin: 0 auto;
    border-radius: 16px;
    font-size: 16px;
    font-weight: 700;
    color: #111111;
    background: #1938ff;
    cursor: pointer;
  }
  @media (max-width: 900px) {
    section.entrance article {
      padding: 90px 28px 120px 28px;
    }
    section.entrance article div.entrance-content {
      width: 100%;
    }
    section.entrance article div.entrance-content div.agreement-wrap {
      padding: 16px 22px;
    }
    section.entrance
      article
      div.entrance-content
      div.agreement-wrap
      div.agreement {
      font-size: 12px;
    }
    section.entrance
      article
      div.entrance-content
      div.input-wrap
      div.checkbox-wrap {
      width: 100%;
      margin-bottom: 40px;
    }
    section.entrance
      article
      div.entrance-content
      div.input-wrap
      div.checkbox-wrap
      p.checkbox-text {
      font-size: 12px;
      padding-top: 2px;
    }
    section.entrance
      article
      div.entrance-content
      div.input-wrap
      div.password-wrap
      p.password-text,
    section.entrance
      article
      div.entrance-content
      div.input-wrap
      div.password-wrap
      #enter {
      font-size: 15px;
    }
    section.entrance
      article
      div.entrance-content
      div.input-wrap
      div.password-wrap
      #p1a2s3s4w5o6r7d,
    section.entrance
      article
      div.entrance-content
      div.input-wrap
      div.password-wrap
      #enter {
      width: 160px;
      height: 36px;
    }
    section.entrance
      article
      div.entrance-content
      div.input-wrap
      div.password-wrap
      #p1a2s3s4w5o6r7d {
      margin-bottom: 14px;
    }
  }
}
@media (max-width: 900px) {
  body.demo {
    margin-top: 70px;
  }
}
